.explore-container {
    height: calc(100vh - 140px);
}

.under-construction {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
}

.under-construction-image {
    width: 300px;
}
