.BlockCard {
    border: 1px solid var(--Gradient-Stroke, #3d3d46);
    background: var(--Brand-Black, #202028);
    box-sizing: border-box;
    border-radius: 10px;
    flex-direction: column;
    flex: 1 0 0;
    align-items: flex-start;
    gap: 40px;
    width: 100%;
    padding: 24px 20px;
    display: flex;
}

.BlockHeader {
    display: flex;
    align-items: center;
    gap: 10px;
}

.ProgressContainer {
    flex-grow: 1;
}