.complete .dx-progressbar-range {
    background-color: green;
}

.dx-progressbar {
    transition: none !important;
}

.dx-progressbar-range {
    transition: none !important;
}
