.BlockCard-CustomerGrid {
    border: 1px solid var(--Gradient-Stroke, #3d3d46);
    background: var(--Brand-Black, #202028);
    box-sizing: border-box;
    border-radius: 10px;
    flex-direction: column;
    flex: 1 0 0;
    align-items: flex-start;
    width: 100%;
    padding: 5px 20px; /* 24px 20px */
    display: flex;
}

.dx-state-hover {
    cursor : pointer;
}