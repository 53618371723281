@import "https://fonts.googleapis.com/css?family=Manrope:0,600;0,500&display=swap";

.dx-button-text {
    text-transform: unset !important;
}

.Frame_18_2093_2751 {
    flex-direction: column;
    flex-shrink: 0;
    align-items: flex-start;
    gap: 24px;
    padding: 24px;
    display: flex;
}

.Frame_34_2093_2752 {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
}

.BlockCard {
    border: 1px solid var(--Gradient-Stroke, #3d3d46);
    background: var(--Brand-Black, #202028);
    box-sizing: border-box;
    border-radius: 10px;
    flex-direction: column;
    flex: 1 0 0;
    align-items: flex-start;
    gap: 40px;
    width: 100%;
    padding: 24px 20px;
    display: flex;
}

.BlockHeader {
    align-self: stretch;
    justify-content: space-between;
    align-items: flex-start;
    display: inline-flex;
}

.BlockTitle {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    display: inline-flex
}