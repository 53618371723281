.path-credential-grid-container {
    height: 600px;
    width: 100%;
}

.under-construction {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.under-construction-image {
    width: 300px;
}
