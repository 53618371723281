@import "https://fonts.googleapis.com/css?family=Manrope:0,600;0,500&display=swap";

.Frame_15_2086_12567 {
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 1160px;
    height: 824px;
    padding: 24px;
    display: flex;
}

.Illustrations_2091_2117 {
    justify-content: center;
    align-items: center;
    width: 240px;
    padding: 37.139px 5.425px 29.017px 5.6px;
    display: flex;
}

.IllustrationFound_2091_2118 {
    flex-shrink: 0;
    width: 228.975px;
    height: 173.844px;
}

.Frame_18_2088_1169 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    display: flex;
}

.Frame_16_2088_1170 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    display: flex;
}

.Headline_2088_1171 {
    color: var(--Neutral-Light-Grey, #e3e3e8);
    text-align: center;
    letter-spacing: 0.76px;
    font-family: Manrope;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    display: inline-block;
}

.Subheadline_2088_1172 {
    color: var(--Neutral-Medium-Grey, #9393a2);
    text-align: center;
    letter-spacing: 0.28px;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    display: inline-block;
}

.Frame_1410103500_2088_1189 {
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
    display: flex;
}

.Button_2088_1190 {
    border: 1px solid var(--Gradient-Button-Stroke, #8f3131);
    background: var(--Brand-Red, #b33d3d);
    box-sizing: border-box;
    border-radius: 8px;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;
    gap: 6px;
    width: 100%;
    height: 48px;
    padding: 8px 12px;
    display: flex;
    overflow: hidden;
    box-shadow: 0 1px 2px #8f24008f, 0 0 0 1px #b33d3d;
}

.CreatePath_2088_1191 {
    color: var(--Neutral-White, #fff);
    letter-spacing: 0.32px;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    display: inline-block;
}

.Icons_2088_1192 {
    width: 18px;
    height: 18px;
    overflow: hidden;
}

.Button_2088_1184 {
    border: 1px solid var(--Gradient-Stroke, #3d3d46);
    background: var(--Brand-Black, #202028);
    box-sizing: border-box;
    border-radius: 8px;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;
    gap: 6px;
    height: 48px;
    padding: 8px 12px;
    display: flex;
}

.DecouvrirCetteOffre_2037_368 {
    color: var(--Neutral-Light-Grey, #e3e3e8);
    letter-spacing: 0.32px;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    display: inline-block;
}
