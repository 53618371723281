@import "https://fonts.googleapis.com/css?family=Manrope:0,600;0,500&display=swap";

.dx-button-text {
    text-transform: unset !important;
}

.Frame_18_2093_2751 {
    flex-direction: column;
    flex-shrink: 0;
    align-items: flex-start;
    gap: 10px;          // 24px
    padding: 0px 24px;  // 24px
    display: flex;
}

.Frame_34_2093_2752 {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
}
