.date-format-container-show {
    display: flex;
    align-items: center;
    padding: 0px 0px;
}

.date-format-container-edit {
    display: flex;
    align-items: center;
    padding: 0px 16px;
}

.date-format-label {
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.icon-calendar {
    font-size: 16px;
    margin-right: 5px;
    color: #666;
}
