.runs-container {
    height: calc(100vh - 140px);
}

.runs-internal-container {
    flex-direction: column;
    flex-shrink: 0;
    align-items: flex-start;
    gap: 10px; // 24px
    padding: 0px 24px; // 24px
    display: flex;
}

.runs-title-container {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
}